/* Styles that can't otherwise be overloaded via fluent ui components */

.ms-CalendarDay-daySelected .ms-CalendarDay-dayIsToday {
  color: white;
  font-weight: 500;
}

.ms-Stack .ms-layer {
  margin: 0;
}

.map-copyright {
  padding-top: 2px;
  padding-bottom: 2px;
}

.azure-map-copyright {
  margin-right: 0;
  margin-left: 0;
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system,
    BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
}

.legend-item-separator:last-child {
  display: none;
}

.ms-Modal .catalog-filter-results-header {
  padding-left: 8px;
}

.ms-Modal h2 {
  scroll-margin-top: 80px;
}

.explorer-mobile-view-map {
  display: none;
}

.explorer-mobile-view-sidebar {
  display: none;
}

@media screen and (min-width: 600px) {
  .ms-Modal .ms-SearchBox {
    min-width: 300px;
  }
}

@media (min-width: 820px) {
  .ms-Modal .catalog-collection-item img {
    width: 200px;
    height: 112px;
  }
}

@media (max-width: 450px) {
  .catalog-selector-toc {
    display: none;
  }
}

@media screen and (max-width: 430px) {
  .explorer-sidebar {
    width: 100vw;
  }

  .explorer-sidebar.explorer-sidebar-hidden {
    width: 0;
    min-width: 0;
  }

  .explorer-map.explorer-sidebar-hidden .explorer-map-component {
    display: initial !important;
  }

  .explorer-map-component {
    display: none !important;
  }

  .explorer-mobile-view-map {
    display: block;
  }

  .explorer-mobile-view-sidebar {
    display: block;
  }

  .explorer-explore-in-hub {
    display: none;
  }

  button .catalog-collection-item {
    padding-left: 0;
  }
}
